import axios from "axios";

let success = function (params) {

    const $page = $('.page-status');

    let isWaitingShown = false;

    const updateStatus = function(){
        axios.post(process.env.API_URL + 'api/order/check', {
            uuid: params.uuid
        })
            .then(function (response) {
                response = response.data;

                if(response.status){
                    const isGift = response.data.is_gift;
                    const uuid = response.data.uuid;
                    if(!isGift) {
                        //DIRECT user
                        if (uuid) {
                            // show test link button
                            // console.log('case button');

                            const redirectUrl = response.data.redirectBaseUrl + 'onetouch/' + uuid;

                            $page.find('.start').attr('href', redirectUrl);
                            $page.find('.status.welcome .customer').html(response.data.customer_email);

                            $page.find('.block.loading').removeClass('active');
                            $page.find('.block.success').addClass('active');
                            $page.find('.status').removeClass('active');
                            $page.find('.status.welcome').addClass('active');
                            $page.find('.footer').addClass('active');


                        } else {
                            // show Waiting status
                            if(!isWaitingShown) {
                                // console.log('case waiting');

                                isWaitingShown = true;
                                $page.find('.status.waiting .customer').html(response.data.customer_email);

                                $page.find('.block.loading').removeClass('active');
                                $page.find('.block.success').addClass('active');
                                $page.find('.status').removeClass('active');
                                $page.find('.status.waiting').addClass('active');
                                $page.find('.footer').addClass('active');
                            }

                            setTimeout(function(){
                                updateStatus();
                            }, 4000);
                        }
                    }else{
                        //show GIFT status
                        // console.log('case gift');

                        $page.find('.status.gift .recipient').html(response.data.recipient_email);

                        $page.find('.block.loading').removeClass('active');
                        $page.find('.block.success').addClass('active');
                        $page.find('.status').removeClass('active');
                        $page.find('.status.gift').addClass('active');
                        $page.find('.footer').addClass('active');
                    }
                }else{
                    window.location.href = '/';
                }

            })
            .catch(function (error) {
                window.location.href = '/';
            });
    };

    updateStatus();

};

export default success;